<template>
  <div class="p-4">
    <auto-updates></auto-updates>
  </div>
</template>
<script>
import AutoUpdates from './AutoUpdates.vue';

export default {
  components: {
    AutoUpdates,
  },
};
</script>
